<template>
  <Main>
    <sdPageHeader :title="i18n.t('profileView.profile')"> </sdPageHeader>
    <a-row :gutter="25">
      <a-col :xxl="6" :lg="8" :md="10" :xs="24">
        <ProfileAuthorBox>
          <author-box />
        </ProfileAuthorBox>
      </a-col>
      <a-col :xxl="18" :lg="16" :md="14" :xs="24">
        <SettingWrapper>
          <Suspense>
            <template #default>
              <router-view></router-view>
            </template>
            <template #fallback>
              <div class="spin">
                <a-spin />
              </div>
            </template>
          </Suspense>
        </SettingWrapper>
      </a-col>
    </a-row>
  </Main>
</template>

<script>
import { Main } from '../../styled';
import { SettingWrapper } from './overview/style';
import AuthorBox from './overview/ProfileAuthorBox';
import { ProfileAuthorBox } from './overview/style';
import { useI18n } from 'vue-i18n';

export default {
  name: 'Settings',
  components: {
    Main,
    SettingWrapper,
    AuthorBox,
    ProfileAuthorBox,
  },
  data() {
    return {
      i18n: useI18n(),
    };
  },
};
</script>

<style scoped lang="scss">
.security-card .ant-card-head-wrapper {
  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
  }
}
</style>
